<div class="top-bar">
  <button mat-icon-button (click)="navigateBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span class="page-title">{{question?.code}} </span>
</div>
<mat-card class="scrollable-card">
  <mat-card-content>
    <form [formGroup]="questionForm" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <mat-label>Tipo risposta</mat-label>
        <mat-select formControlName="answerType">
          <mat-option *ngFor="let type of ANSWER_TYPES" [value]="type.value">
            {{ type.label }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="questionForm.get('answerType')?.hasError('required')">
          Seleziona un tipo risposta
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Nr massimo risposte</mat-label>
        <input matInput formControlName="maxAnswers" type="number" />
      </mat-form-field>

      <br>

      <div formArrayName="questionTexts" class="container-questions">
        <div *ngFor="let text of questionTexts.controls; let i = index">
          <div [formGroupName]="i" *ngIf="!text.get('_delete')?.value">
            <mat-form-field>
              <mat-label>Codice lingua</mat-label>
              <input matInput formControlName="languageCode" />
              <mat-error *ngIf="text.get('languageCode')?.hasError('required')">
                Inserisci una lingua
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Testo</mat-label>
              <input matInput formControlName="text" />
              <mat-error *ngIf="text.get('text')?.hasError('required')">
                Inserisci un testo
              </mat-error>
            </mat-form-field>

            <button mat-icon-button color="warn" type="button" (click)="deleteQuestionText(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <br>
        <button mat-button type="button" (click)="addQuestionText()">Add Text</button>
      </div>

      <br>

      <button *appHasRole="['admin','owner']" mat-raised-button color="warn" type="button" (click)="deleteQuestion()">Elimina</button>
<!--      <button *appHasRole="['editor','admin','owner']" mat-raised-button color="primary" type="submit"  [disabled]="questionForm.invalid || isLoading">Salva</button>-->
      <button *appHasRole="['editor','admin','owner']" mat-raised-button color="primary" type="submit" [disabled]="questionForm.invalid || isLoading || isSubmitting">
        <ng-container *ngIf="!isSubmitting">Salva</ng-container>
        <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
      </button>
    </form>
  </mat-card-content>
</mat-card>

<div *ngIf="isLoading" class="loader-container">
  <mat-spinner></mat-spinner>
</div>