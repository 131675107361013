import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, RouterStateSnapshot } from '@angular/router';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { routes } from './app.routes';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { CustomDateAdapter } from './adapters/custom-date.adapter';
import { PrivacyEventDialogComponent } from './components/persons/edit/privacy-event-dialog/privacy-event-dialog.component';
import { HasRoleDirective } from './directives/has-role.directive';
import { SessionStorageService } from './services/session-storage.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import {
  MsalModule,
  MsalService,
  MSAL_INSTANCE,
  MsalGuard,
  MsalInterceptor,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';

registerLocaleData(localeIt);

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function MSALInstanceFactory() {
  return new PublicClientApplication({
    // todo: chiedere a fiera sso
    // todo: prendere config da env
    auth: {
      clientId: 'your-client-id', // Replace with your client ID
      authority: 'https://login.microsoftonline.com/your-tenant-id', // Replace with your tenant ID
      redirectUri: 'http://localhost:4200', // Replace with your redirect URI
    },
  });
}

export function MSALGuardConfigFactory(msalService: MsalService): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: (authService: MsalService, state: RouterStateSnapshot) => {
      return {
        // todo: capire che valore ha
        scopes: ['user.read'],
      };
    },
    // todo: capire se serve pagina di login failed
    loginFailedRoute: '/login-failed',
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    // todo: capire se corretto questo
    protectedResourceMap: new Map([['https://graph.microsoft.com/v1.0/me', ['user.read']]]),
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(),
    provideAnimationsAsync(),
    importProvidersFrom(MatNativeDateModule),
    importProvidersFrom(MatSnackBarModule),
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: PrivacyEventDialogComponent },
    importProvidersFrom(HasRoleDirective),
    SessionStorageService,
    SnackbarComponent,
    importProvidersFrom(MsalModule),
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [MsalService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalInterceptor,
  ],
};
